* {
  box-sizing: border-box;
}

html {
  font-family: 'Assistant', sans-serif;
  line-height: 1.6;
}

body {
  margin: 0;
  background-size: 180px;
  background-attachment: fixed;
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Assistant', sans-serif !important;
  margin: 0 0 1rem;
  font-weight: 500;
}

h1 {
  font-size: rem(50px);
}

p {
  max-width: 48ch;
  margin: 0 0 1rem;
}

img {
  width: 100%;
}

.autoWith {
  width: auto;
}
